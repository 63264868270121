<template>
	<div class="main-contents hr agent">

		<div class="tit" v-if="input.gubun == 'new'">매니저 배정<img src="/images/admin/list_shortcut.png" alt="목록가기" @click="$router.go(-1)"/>
			<img v-if="input.gubun == 'new'" @click="clickChangeAgent()" src="/images/admin/agent-assign_btn.png" alt="선택한매니저로배정하기"/>
		</div>

		<div class="tit" v-else-if="input.gubun == 'mod'">매니저 변경<img src="/images/admin/list_shortcut.png" alt="목록가기" @click="$router.go(-1)"/>
			<img v-if="input.gubun == 'mod'" @click="clickChangeAgent()" src="/images/admin/agent-change_btn.png" alt="선택한매니저로변경하기"/>
		</div>

		<div class="search-box interval">
			<InputComp type="text" placeholder="매니저명" 
						v-model="input.searchKeyword" @keyup.enter="getAgentList('1')"  />
			<nbsp/>
			<img @click="getAgentList('1')" class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼"/>
		</div>

		<div class="Board type3">
			<table class="Board_type3 admin">
				<colgroup>
					<col width="4%">
					<col width="4%">
					<col width="11%">
					<col width="12%">
					<col width="12%">
					<col width="4%">
					<col width="18%">
					<col width="18%">
					<col width="18%">
				</colgroup>
				<thead>
					<tr>
						<th>선택</th>
						<th>NO</th>
						<th>매니저</th>
						<th>관리자 번호</th>
						<th>아이디</th>
						<th>성별</th>
						<th>수행중인 프로젝트</th>
						<th>수행예정 프로젝트</th>
						<th>완료 프로젝트</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(agent, index) in agentList" :key="agent.mberSeq">
						<td class="radio">
							<input type="radio" v-model="input.admMgrMberSeq" :id="index" :value="agent.mberSeq" @change="changeRadio(index)">
							<label :for="index">
								<span></span>
							</label>
						</td>
						<td class="score">{{ (pageInfo.pageIndex - 1) * pageInfo.pageUnit + (index + 1)}}</td>
						<td class="score name">
							<span @click="popupAgentMber(agent.mberSeq)">{{agent.mberNm}} &#9654;</span>
						</td>
						<td class="score">{{agent.mberSeq}}</td>
						<td class="score">{{agent.loginId}}</td>
						<td class="score">{{agent.gender | gender}}</td>
						
						<td class="score">{{agent.projectIngCnt}}</td>
						<td class="score">{{agent.projectPreCnt}}</td>
						<td class="score">{{agent.projectEndCnt}}</td>
						
					</tr>
					<!-- 관련내용 없을 경우 -->
					<tr v-if="pageInfo.totalRecordCount == 0">
						<td colspan="9" class="none">매니저가 없습니다!</td>
					</tr>
				</tbody>
			</table>
		</div>

		<!-- 페이징부분 -->
		<paging-comp :pageInfo="pageInfo" @page-click="goPage"/>
	</div><!-- //main-contents -->
</template>
<script>
import pagingComp from '@/components/PagingComp.vue';

export default {
	components: { pagingComp },
	data() {
		return {
			input : {
				gubun : '',		// new : 신규 배정, mod : 변경

				projectSeq: '',
				projectNm : '',

				currAdmMgrMberSeq : '',
				currAdmMgrMberNm : '',
				currAdmMgrLoginId : '',

				admMgrMberSeq : '',		// 변경할 매니저
				admMgrMberNm : '',
				admMgrLoginId : '',

				searchKeyword : '',
			},

			agentList : [],			
			pageInfo : {},
		};
	},
	mounted(){

		var keys = Object.keys(this.input);
		for(var key in this.$route.params){
			if(keys.indexOf(key) >= 0){
				this.input[key] = this.$route.params[key];
			}
		}

		this.getAgentList();
	},
	methods: {
 		getAgentList(div) {
			if(div) this.input.pageIndex = 1;
			
			this.$.httpPost('/api/prj/adm/getAgentList', this.input)
				.then(res => {
					// console.log(res.data);
					this.agentList = res.data.list;
					this.pageInfo = res.data.pageInfo;
					
					window.scroll(0, 0);
				})
				.catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				});
		}, 
		
		goPage(page) {
			// console.log(page);
			this.input.pageIndex = page;
			this.getList();
		},

		changeRadio(index) {
			this.input.admMgrMberSeq = this.agentList[index].mberSeq;
			this.input.admMgrMberNm = this.agentList[index].mberNm;
			this.input.admMgrLoginId = this.agentList[index].loginId;
		},

		clickChangeAgent() {

			var input = {
				projectSeq	: this.input.projectSeq,
				projectNm 	: this.input.projectNm,
			
				currAdmMgrMberSeq : this.input.currAdmMgrMberSeq,	
				currAdmMgrMberNm : this.input.currAdmMgrMberNm,
				currAdmMgrLoginId : this.input.currAdmMgrLoginId,

				admMgrMberSeq : this.input.admMgrMberSeq,		// 선택한 매니저
				admMgrMberNm : this.input.admMgrMberNm,
				admMgrLoginId : this.input.admMgrLoginId
			}

			var popupUrl = '';
			if(this.input.gubun == 'new') {
				popupUrl = '/adm/prj/PRJ910P04';
			} else if(this.input.gubun == 'mod') {
				popupUrl = '/adm/prj/PRJ910P03';
			}

			this.$.popup(popupUrl, input)
                .then(res => {
                    if(res) {
                    	this.$router.go(-1);
                    }
                });
		},

		// 매니저 정보
		popupAgentMber(mberSeq) {
			var param = {};
			param.reqMberSeq = mberSeq;
			param.div = 'agt'; // tec - 개인, gmg - 관리총괄, pmg - 프로젝트 담당자, sco - 공급기업, agt - 매니저

			this.$.popup('/adm/mem/MEM923P02', param);
		},		
		
	},
};
</script>
